<template>
    <v-container>
        <AConfirmation :isShow="isPendingDeleteTemporary" @cancel="cancelSubmit" @confirm="deleteCompanyTemporaryInvalid"/>
        <AConfirmation :isShow="isPendingDeleteInvalid" @cancel= "cancelSubmitDeleteInvalid" @confirm="deleteCompanyInvalid"/>
        <AConfirmation :isShow="isPendingUpdateTemporaryStatus" @cancel="cancelCompanyTemporaryStatus" @confirm="updateCompanyTemporaryStatus"/>
        <v-alert type="success" border="left" dense v-if="api.isSuccesful
        ">
            {{ this.api.success }}
        </v-alert>

        <v-toolbar flat class="pa-5" height="auto" width="auto">
            <div class="d-flex flex-column">
                <div class="d-flex flex-row my-4">
                    <div class="d-flex mx-4">
                        Update All Companies With TEMPORARY name to Invalid
                    </div>
                    <div class="d-flex">
                        <v-btn color="primary" @click="validateCompanyTemporaryStatus">
                            Go
                        </v-btn>
                    </div>
                </div>
                <div class="d-flex flex-row my-4">
                    <div class="d-flex mx-4">
                        Delete All companies With TEMPORARY name and Invalid status
                    </div>
                    <div>
                        <v-btn color="red" outlined @click="validateConfirmation">
                            Delete
                        </v-btn>
                    </div>
                </div>
                <div class="d-flex flex-row my-4">
                    <div class="d-flex mx-4">
                        Delete all Invalid companies
                    </div>
                    <div class="d-flex">
                        <v-btn color="red" outlined @click="validateConfirmationDeleteInvalid">
                            Delete
                        </v-btn>
                    </div>
                </div>
            </div>
            <!-- <v-col>
                <v-row>
                    <v-card-title class="font-weight-bold text-h5">
                        Clean companies
                    </v-card-title>
                </v-row>
                <v-row>
                    <v-btn color="primary">
                        
                    </v-btn>
                </v-row>
            </v-col> -->
        </v-toolbar>
        
        <v-card>
            <div>

            </div>
        </v-card>
    </v-container>

</template>
<script>
import AConfirmation from '../../common/AConfirmation.vue';

export default{
    data: () => ({
        isPendingDeleteTemporary:false,
        isPendingDeleteInvalid:false,
        isPendingUpdateTemporaryStatus:false,
        api: {
            isSuccesful: false,
            isLoading: false,
            isError: false,
            error: null,
            url: null,
            success:null,
        }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.status==401){
                this.api.error = "Invalid access",
                this.api.isError = true;
                this.api.isLoading = false;
                return 1;
            }
            if(resp.class=="deleteCompanyTemporaryInvalid"){
                this.api.isSuccesful = true;
                this.api.success = "Succesfully delete temporary invalid companies";
            }
           if(resp.class=="deleteCompanyByVerification"){
                this.api.isSuccesful = true;
                this.api.success="Succesfully delete the companies";
           }
           if(resp.class=="updateCompanyTemporaryToInvalid"){
                this.api.isSuccesful = true;
                this.api.success = "Succesfully update the temporary company verifications status to invalid";
           }
            this.api.isLoading = false;
            return resp.data;
            
        };

    },
    mounted() {
    },
    methods: {
        fetch() {
        },
        deleteCompanyInvalid(){
            this.isPendingDeleteInvalid = false;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "DELETE";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/company/verification/invalid";
            tempApi.params={
                'id':this.$store.getters.getUserId,
            };
            this.$api.fetch(tempApi);

        },
        deleteCompanyTemporaryInvalid(){
            this.isPendingDeleteTemporary = false;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "DELETE";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/company/temporary/invalid/"+this.$store.getters.getUserId;
         
            this.$api.fetch(tempApi);
        },
        updateCompanyTemporaryStatus(){
            this.isPendingUpdateTemporaryStatus = false;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/company/temporary/invalid";
            this.$api.fetch(tempApi);
        },
        validateCompanyTemporaryStatus(){
            this.isPendingUpdateTemporaryStatus = true;
        },
        validateConfirmation(){
            this.isPendingDeleteTemporary = true;
        },
        validateConfirmationDeleteInvalid(){
            this.isPendingDeleteInvalid = true;
        },
        cancelCompanyTemporaryStatus(){
            this.isPendingUpdateTemporaryStatus = false;
        },
        cancelSubmitDeleteInvalid(){
            this.isPendingDeleteInvalid = false;
        },
        cancelSubmit(){
            this.isPendingDeleteTemporary = false;
        },

    },
    components: { AConfirmation }
}
</script>