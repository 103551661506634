<template>
    <v-container>
        <v-tabs v-model="tab">
            <!-- <v-tab>
                Manual
            </v-tab> -->
            <v-tab>
                Report
            </v-tab>
            <v-tab>
                Clean                
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item>
               <ComponentDeveloperCompanyReport/>
            </v-tab-item>
            <v-tab-item>
               <ComponentDeveloperCompanyClean/>
            </v-tab-item>

        </v-tabs-items>
    </v-container>    
</template>
<script>
import ComponentDeveloperCompanyClean from '../../components/developer/company/ComponentDeveloperCompanyClean.vue';
import ComponentDeveloperCompanyReport from '../../components/developer/company/ComponentDeveloperCompanyReport.vue';


export default{
    data: () => ({
        tab: null,
        verification: "valid",
        totalCompaniesByVerification: null,
        api: {
            isSucessful: false,
            isLoading: false,
            isError: false,
            error: null,
            url: null,
        }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if (resp.class == "checkCompanyGroupVerification") {
                this.totalCompaniesByVerification = resp.data;
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        };
    },
    mounted() {
        // this.fetch();
    },
    methods: {
        fetch() {
            let fetchTotalCompaniesApi = this.fetchTotalCompanies();
            this.$api.fetch(fetchTotalCompaniesApi);
        },
        fetchTotalCompanies() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/developer/company/verification";
            this.$api.fetch(tempApi);
        },
    },
    components: { ComponentDeveloperCompanyReport, ComponentDeveloperCompanyClean }
}
</script>
