<template>
    <v-container>
        <v-toolbar height="auto" width="auto" flat class="mb-4">

            <div class="d-flex flex-column">
                <div>
                    <div class="d-flex flex-row my-4">
                        <div class="text-h4 font-weight-bold">
                            Clean the company First Draft
                        </div>
                    </div>
                    <div class="d-flex flex-row my-4">
                        <div class="d-flex mx-4">
                        1. Get List of all companies exclude temporary
                        </div>
                        <div class="d-flex ">
                            <v-btn color="primary" @click="fetchAllCompaniesExcludeTemporary"> 
                                Go
                            </v-btn>
                        </div>
                    </div>
                    <div>
                        <div>
                            <v-progress-circular indeterminate color="primary" v-if="loadingCompaniesWithoutTemporary">

                            </v-progress-circular>
                        <v-data-table :search="searchCompaniesWithoutTemporary" class="elevation-3 mt-4" dense outlined :headers="companiesNotFoundHeader" :items="this.companiesWithoutTemporary" v-if="this.companiesWithoutTemporary!=null">
                            <template v-slot:top>
                                <v-toolbar flat height="auto" class="ma-5">
                                    <v-row no-gutters color="primary class=mt-4">
                                        <v-col class="mt-3 mx-3">
                                            <div class="font-weight-bold text-h4">
                                                All companies without temporary
                                            </div>
                                            <v-text-field class="mt-4" v-model="searchCompaniesWithoutTemporary" label="Search company" clearable filled outlined dense>
        
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-spacer>
                                    </v-spacer>
                                    <v-btn color="primary" @click="updateCompanyToUnknownAllCompaniesWithoutTemporary">
                                        Update To Unknown
                                    </v-btn>
                                </v-toolbar>
                            </template>
                        </v-data-table>
                    </div>
                    </div>


                    <div class="d-flex flex-row my-4">
                        <div class="d-flex mx-4">
                        2. Get List of Companies From Companies not found with the sales list
                        </div>
                        <div class="d-flex ">
                            <v-btn color="primary" @click="fetchCompaniesNotFoundInExcel"> 
                                Go
                            </v-btn>
                        </div>


                        
                    </div>
                    

                    <div>
                        <v-progress-circular indeterminate color="primary" v-if="loadingCompanyNotFoundExcel">

                        </v-progress-circular>
                        <v-data-table :search="searchCompanyNotFoundExcel" class="elevation-3 mt-4" dense outlined :headers="companiesNotFoundHeader" :items="this.companiesNotFoundInExcel" v-if="this.companiesNotFoundInExcel!=null">
                            <template v-slot:top>
                                <v-toolbar flat height="auto" class="ma-5">
                                    <v-row no-gutters color="primary class=mt-4">
                                        <v-col class="mt-3 mx-3">
                                            <div class="font-weight-bold text-h4">
                                                Unmatched Company 
                                            </div>
                                            <v-text-field class="mt-4" v-model="searchCompanyNotFoundExcel" label="Search company" clearable filled outlined dense>
        
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-spacer>
                                    </v-spacer>
                                    <v-btn color="primary" @click="updateCompanyUnmatchedToInvalid">
                                        Update To Invalid
                                    </v-btn>
                                </v-toolbar>
                            </template>
                        </v-data-table>
                    </div>

                    <div class="d-flex flex-row my-4">
                        <div class="d-flex mx-4">
                        3. Get List of Companies From Companies Intersect With sales vision Key
                        </div>
                        <div class="d-flex ">
                            <v-btn color="primary" @click="fetchCompaniesIntersectVisionKey"> 
                                Go
                            </v-btn>
                        </div>
                    </div>
                    <v-progress-circular indeterminate color="primary" v-if="loadingCompanyIntersectVisionKey">

                    </v-progress-circular>
                    <v-data-table :search="searchCompanyIntersectVisionKey" class="elevation-3 mt-4" dense outlined :headers="companiesHeader" v-if="companiesIntersectVisionKey!=null" :items="companiesIntersectVisionKey"
                    :footer-props="{
                        showFirstLastPage:true,
                    }">
                        <template v-slot:top>
                            <v-toolbar flat height="auto" class="ma-5">
                                <v-row no-gutters color="primary class=mt-4">
                                    <v-col class="mt-3 mx-3">
                                        <div class="font-weight-bold text-h4">
                                            Matched Company
                                        </div>
                                        <v-text-field class="mt-4" v-model="searchCompanyIntersectVisionKey" label="Search company" clearable filled outlined dense>
        
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-spacer>
                                </v-spacer>
                                <v-btn color="primary" @click="updateCompanyToValidIntersectSalesList">
                                    Update To Valid
                                </v-btn>
                            </v-toolbar>
                        </template>
                        <template v-slot:item.index="{item}">
                                {{ companiesIntersectVisionKey.indexOf(item)+1 }}
                        </template>
                    </v-data-table>

                    <!-- <v-data-table class="elevation-3 mt-4" color="primary" v-if="companiesProblem" :items="companiesProblem">

                    </v-data-table> -->
                    <v-simple-table max-    height="500px" v-if="companiesProblem!=null" dense outlined class="elevation-3 mt-5">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>
                                        Name
                                    </th>
                                </tr>
                            </thead>
                            <tbody >
                                <tr v-for="company in companiesProblem" :key="company">
                                    <td>
                                        {{ company }}
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                </div>

                <div>
                    <div class="d-flex flex-row my-4">
                        <div class="font-weight-bold text-h4">
                            Clean Testing Company
                        </div>
                    </div>

                    <div class="d-flex flex-row my-4">
                        <div class="d-flex mx-4">
                        Secret key
                        </div>
                        <div class="d-flex ">
                            <v-text-field v-model="secretKey" label="Please insert secret key if you want to proceed" dense outlined>
                            </v-text-field>
                        </div>
                    </div>

                    <div class="d-flex flex-row my-4">
                        <div class="d-flex mx-4">
                        1. Enter the company id
                        </div>
                        <div class="d-flex ">
                            <v-text-field v-model="cleanCompanyId" label="Company id" dense outlined>
                            </v-text-field>
                        </div>
                    </div>
                    

                    <div class="d-flex flex-row my-4">
                        <div class="d-flex mx-4">
                        2. Find the renewal status
                        </div>
                        <div class="d-flex ">
                            <v-btn color="primary" @click="fetchCompaniesTestingRenewalStatus">
                                Go
                            </v-btn>
                        </div>
                    </div>
                    <v-progress-circular indeterminate color="primary" v-if="loadingCleanCompaniesRenewal">
                    </v-progress-circular>
                     <v-data-table :search="searchCleanCompaniesRenewal" class="elevation-3 mt-4" dense outlined :headers="companiesRenewalHeader" :items="this.cleanCompanyRenewal" v-if="this.cleanCompanyRenewal !=null">
                            <template v-slot:top>
                                <v-toolbar flat height="auto" class="ma-5">
                                    <v-row no-gutters color="primary class=mt-4">
                                        <v-col class="mt-3 mx-3">
                                            <div class="font-weight-bold text-h4">
                                                All renewal status
                                            </div>
                                            <v-text-field class="mt-4" v-model="searchCleanCompaniesRenewal" label="Search" clearable filled outlined dense>
        
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-spacer>
                                    </v-spacer>
                                    <v-btn color="primary" @click="ClearCompaniesRenewal">
                                        Clear 
                                    </v-btn>
                                </v-toolbar>
                            </template>
                        </v-data-table>



                    <div class="d-flex flex-row my-4">
                        <div class="d-flex mx-4">
                        3. Find the payment status
                        </div>
                        <div class="d-flex ">
                            <v-btn color="primary" @click="fetchCompaniesTestingPaymentStatus">
                                Go
                            </v-btn>
                        </div>
                    </div>
                    <v-progress-circular indeterminate color="primary" v-if="loadingCleanCompaniesPayment">
                    </v-progress-circular>
                     <v-data-table :search="searchCleanCompaniesPayment" class="elevation-3 mt-4" dense outlined :headers="companiesPaymentHeader" :items="this.cleanCompanyPayment" v-if="this.cleanCompanyPayment !=null">
                            <template v-slot:top>
                                <v-toolbar flat height="auto" class="ma-5">
                                    <v-row no-gutters color="primary class=mt-4">
                                        <v-col class="mt-3 mx-3">
                                            <div class="font-weight-bold text-h4">
                                                All payment status
                                            </div>
                                            <v-text-field class="mt-4" v-model="searchCleanCompaniesPayment" label="Search" clearable filled outlined dense>
        
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-spacer>
                                    </v-spacer>
                                    <v-btn color="primary" @click="ClearCompaniesPayment">
                                        Clear 
                                    </v-btn>
                                </v-toolbar>
                            </template>
                        </v-data-table>


                    <div class="d-flex flex-row my-4">
                        <div class="d-flex mx-4">
                        4. Find the proof of payment
                        </div>
                        <div class="d-flex ">
                            <v-btn color="primary" @click="fetchCompaniesTestingReceipt">
                                Go
                            </v-btn>
                        </div>
                    </div>

                    <v-progress-circular indeterminate color="primary" v-if="loadingCleanCompaniesReceipt">
                    </v-progress-circular>
                     <v-data-table style="max-width: 500px;" :search="searchCleanCompaniesReceipt" class="elevation-3 mt-4" dense outlined :headers="companiesReceiptHeader" :items="this.cleanCompanyReceipt" v-if="this.cleanCompanyReceipt !=null">
                            <template v-slot:top>
                                <v-toolbar flat height="auto" class="ma-5">
                                    <v-row no-gutters color="primary class=mt-4">
                                        <v-col class="mt-3 mx-3">
                                            <div class="font-weight-bold text-h4">
                                                All receipt
                                            </div>
                                            <v-text-field class="mt-4" v-model="searchCleanCompaniesReceipt" label="Search" clearable filled outlined dense>
        
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-spacer>
                                    </v-spacer>
                                    <v-btn color="primary" @click="ClearCompaniesReceipt">
                                        Clear 
                                    </v-btn>
                                </v-toolbar>
                            </template>
                        </v-data-table>



                    <div class="d-flex flex-row my-4">
                        <div class="d-flex mx-4">
                        5. Find the invoice
                        </div>
                        <div class="d-flex ">
                            <v-btn color="primary" @click="fetchCompaniesTestingInvoice">
                                Go
                            </v-btn>
                        </div>
                    </div>
                    <v-progress-circular indeterminate color="primary" v-if="loadingCleanCompaniesInvoice">
                    </v-progress-circular>
                    <v-data-table style="max-width: 500px;"  :search="searchCleanCompaniesInvoice" class="elevation-3 mt-4" dense outlined :headers="companiesReceiptHeader" :items="this.cleanCompanyInvoice" v-if="this.cleanCompanyInvoice !=null">
                            <template v-slot:top>
                                <v-toolbar flat height="auto" class="ma-5">
                                    <v-row no-gutters color="primary class=mt-4">
                                        <v-col class="mt-3 mx-3">
                                            <div class="font-weight-bold text-h4">
                                                All Invoices
                                            </div>
                                            <v-text-field class="mt-4" v-model="searchCleanCompaniesInvoice" label="Search" clearable filled outlined dense>
        
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-spacer>
                                    </v-spacer>
                                    <v-btn color="primary" @click="ClearCompaniesInvoice">
                                        Clear 
                                    </v-btn>
                                </v-toolbar>
                            </template>
                        </v-data-table>



                    <div class="d-flex flex-row my-4">
                        <div class="d-flex mx-4">
                        6. Find the pic
                        </div>
                        <div class="d-flex ">
                            <v-btn color="primary" @click="fetchCompaniesTestingPic">
                                Go
                            </v-btn>
                        </div>
                    </div>
                    <v-progress-circular indeterminate color="primary" v-if="loadingCleanCompaniesPic">
                    </v-progress-circular>
                    <v-data-table style="max-width: 500px;"  :search="searchCleanCompaniesPic" class="elevation-3 mt-4" dense outlined :headers="companiesPicHeader" :items="this.cleanCompanyPic" v-if="this.cleanCompanyPic!=null">
                            <template v-slot:top>
                                <v-toolbar flat height="auto" class="ma-5">
                                    <v-row no-gutters color="primary class=mt-4">
                                        <v-col class="mt-3 mx-3">
                                            <div class="font-weight-bold text-h4">
                                                All PIC
                                            </div>
                                            <v-text-field class="mt-4" v-model="searchCleanCompaniesPic" label="Search" clearable filled outlined dense>
        
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-spacer>
                                    </v-spacer>
                                    <v-btn color="primary" @click="ClearCompaniesPic">
                                        Clear 
                                    </v-btn>
                                </v-toolbar>
                            </template>
                        </v-data-table>



                    <div class="d-flex flex-row my-4">
                        <div class="d-flex mx-4">
                        7. Find the notification
                        </div>
                        <div class="d-flex ">
                            <v-btn color="primary" @click="fetchCompaniesTestingNotification">
                                Go
                            </v-btn>
                        </div>
                    </div>
                    <v-progress-circular indeterminate color="primary" v-if="loadingCleanCompaniesNotification">
                    </v-progress-circular>
                    <v-data-table style="max-width: 500px;"  :search="searchCleanCompaniesNotification" class="elevation-3 mt-4" dense outlined :headers="companiesNotificationHeader" :items="this.cleanCompanyNotification" v-if="this.cleanCompanyNotification!=null">
                            <template v-slot:top>
                                <v-toolbar flat height="auto" class="ma-5">
                                    <v-row no-gutters color="primary class=mt-4">
                                        <v-col class="mt-3 mx-3">
                                            <div class="font-weight-bold text-h4">
                                                All Notification
                                            </div>
                                            <v-text-field class="mt-4" v-model="searchCleanCompaniesPic" label="Search" clearable filled outlined dense>
        
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-spacer>
                                    </v-spacer>
                                    <v-btn color="primary" @click="ClearCompaniesNotification">
                                        Clear 
                                    </v-btn>
                                </v-toolbar>
                            </template>
                        </v-data-table>

                    <div class="d-flex flex-row my-4">
                        <div class="d-flex mx-4">
                        8. Find the task
                        </div>
                        <div class="d-flex ">
                            <v-btn color="primary" @click="fetchCompaniesTestingTask">
                                Go
                            </v-btn>
                        </div>
                    </div>

                    <v-progress-circular indeterminate color="primary" v-if="loadingCleanCompaniesTask">
                    </v-progress-circular>
                    <v-data-table style="max-width: 500px;"  :search="searchCleanCompaniesTask" class="elevation-3 mt-4" dense outlined :headers="companiesTaskHeader" :items="this.cleanCompanyTask" v-if="this.cleanCompanyTask!=null">
                            <template v-slot:top>
                                <v-toolbar flat height="auto" class="ma-5">
                                    <v-row no-gutters color="primary class=mt-4">
                                        <v-col class="mt-3 mx-3">
                                            <div class="font-weight-bold text-h4">
                                                All Task
                                            </div>
                                            <v-text-field class="mt-4" v-model="searchCleanCompaniesPic" label="Search" clearable filled outlined dense>
        
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-spacer>
                                    </v-spacer>
                                    <v-btn color="primary" @click="ClearCompaniesTask">
                                        Clear 
                                    </v-btn>
                                </v-toolbar>
                            </template>
                        </v-data-table>



                    <div class="d-flex flex-row my-4">
                        <div class="d-flex mx-4">
                        9. Find the Log
                        </div>
                        <div class="d-flex ">
                            <v-btn color="primary" @click="fetchCompaniesTestingLog">
                                Go
                            </v-btn>
                        </div>
                    </div>

                    <v-progress-circular indeterminate color="primary" v-if="loadingCleanCompaniesLog">
                    </v-progress-circular>
                    <v-data-table style="max-width: 500px;"  :search="searchCleanCompaniesLog" class="elevation-3 mt-4" dense outlined :headers="companiesLogHeader" :items="this.cleanCompanyLog" v-if="this.cleanCompanyLog!=null">
                            <template v-slot:top>
                                <v-toolbar flat height="auto" class="ma-5">
                                    <v-row no-gutters color="primary class=mt-4">
                                        <v-col class="mt-3 mx-3">
                                            <div class="font-weight-bold text-h4">
                                                All Log
                                            </div>
                                            <v-text-field class="mt-4" v-model="searchCleanCompaniesLog" label="Search" clearable filled outlined dense>
        
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-spacer>
                                    </v-spacer>
                                    <v-btn color="primary" @click="ClearCompaniesLog">
                                        Clear 
                                    </v-btn>
                                </v-toolbar>
                            </template>
                        </v-data-table>


                </div>




                <div>
                    <div class="d-flex flex-row my-4">
                        <div class="text-h4 font-weight-bold">
                            Report JSON
                        </div>
                    </div>
                    <div class="d-flex flex-row my-4">
                        <div class="d-flex mx-4">
                            Get List of Companies From Sales List
                        </div>
                        <div class="d-flex ">
                            <v-btn color="primary" @click="fetchCompaniesFromExcel"> 
                                Go
                            </v-btn>
                        </div>
                    </div>
                    <div class="d-flex flex-row justify-start my-4">
                        <div class="mx-4">
                            Get list of companies in companies table
                        </div>
                        <div>
        
                            <v-btn color="primary" @click="fetchTotalCompanies">
                                Go
                            </v-btn>
                        </div>
                    </div>
                    <div class="d-flex flex-row justify-start my-4">
                        <div class="mx-4">
                        Get list of companies from table companies intersect with excel
                        </div>
                        <div>
                        <v-btn color="primary" @click="fetchCompaniesFromExcel"> 
                            Go
                        </v-btn>
                        </div>
                    </div>

                </div>
            </div>
            <!-- <v-col >
                <v-row class="my-3">
                    <div class="font-weight-bold text-h5">
                        Clean Company
                    </div>
                </v-row>
                <v-row class="my-3">
                 
                </v-row>
                
                <v-row class="my-3">

                </v-row>
                <v-row class="my-3">
                    
                </v-row>
                

            </v-col> -->
        </v-toolbar>
        <v-card  v-if="totalCompaniesByVerification!=null" class="pa-4 my-5">
            <div class="d-flex justify-end">
                <v-btn color="primary" @click="()=>{totalCompaniesByVerification=null}">
                    Close
                </v-btn>
            </div>
            <div class="d-flex flex-column">
                <div>
                    <div class="d-flex justify-center">
                        <div>
                            Total of companies in companies table :
                        </div>  
                    </div>
                    <v-skeleton-loader v-if="loadingCompanyVerification" ref="skeleton" type="table">
                    </v-skeleton-loader>

                    <div v-if="this.totalCompaniesByVerification">
                        <!-- For valid companies -->
                        <div>
                            <div class="d-flex justify-center">
                                <div class="font-weight bold text-h7">
                                    Valid
                                </div>
                            </div>
                            <div class="d-flex justify-center">
                                <div>
                                    Total Companies {{ this.totalCompaniesByVerification.valid.total }}
                                </div>
                            </div>
                            <v-textarea  filled solo width="auto" height="auto" v-model="this.totalCompaniesByVerification.valid.companies">
                            </v-textarea>
                        </div>
                        <!-- For invalid companies -->
                        <div>
                            <div class="d-flex justify-center">
                                <div class="font-weight bold text-h7">
                                    Invalid
                                </div>
                            </div>
                            <div class="d-flex justify-center">
                                <div>
                                    Total Companies {{ this.totalCompaniesByVerification.invalid.total }}
                                </div>
                            </div>
                            <v-textarea  filled solo width="auto" height="auto" v-model="this.totalCompaniesByVerification.invalid.companies">
                            </v-textarea>
                        </div>
                        <div>
                            <div class="d-flex justify-center">
                                <div class="font-weight bold text-h7">
                                    Unknown
                                </div>
                            </div>
                            <div class="d-flex justify-center">
                                <div>
                                    Total Companies {{ this.totalCompaniesByVerification.unknown.total }}
                                </div>
                            </div>
                            <v-textarea  filled solo width="auto" height="auto" v-model="this.totalCompaniesByVerification.unknown.companies">
                            </v-textarea>
                        </div>
        
                        <div>
                            <div class="d-flex justify-center">
                                <div class="font-weight bold text-h7">
                                    Internal
                                </div>
                            </div>
                            <div class="d-flex justify-center">
                                <div>
                                    Total Companies {{ this.totalCompaniesByVerification.internal.total }}
                                </div>
                            </div>
                            <v-textarea  filled solo width="auto" height="auto" v-model="this.totalCompaniesByVerification.internal.companies">
                            </v-textarea>
                        </div>
                    </div>

                  
                </div>
            </div>
        </v-card>
        <v-skeleton-loader v-if="loadingCompanyExcel" ref="skeleton" type="table">

        </v-skeleton-loader>
        <v-card  class="pa-4 mt-5" v-if="this.totalCompaniesFromExcel!=null">
            <div class="d-flex justify-end">
                <v-btn color="primary" @click="()=>{totalCompaniesFromExcel=null}">
                    Close
                </v-btn>
            </div>
            <div >
                <div class="d-flex justify-center">
                    <div class="">
                        Companies from excel
                    </div>
                </div>
                <div class="d-flex justify-center">
                    <div class="">
                        Total companies : {{ this.totalCompaniesFromExcel.total }}
                    </div>
                </div>
                <v-textarea filled solo v-model="this.totalCompaniesFromExcel.companies">
                </v-textarea>
            </div>
        </v-card>
    </v-container>
</template>
<script>

export default{
    data:()=>({
        cleanCompanyId:null,
        cleanCompanyLog:null,
        cleanCompanyNotification:null,
        cleanCompanyTask:null,
        cleanCompanyPic:null,
        cleanCompanyRenewal:null,
        cleanCompanyInvoice:null,
        cleanCompanyReceipt:null,
        cleanCompanyPayment:null,
        companiesIntersectVisionKey:null,
        companiesWithoutTemporary:null,
        loadingCleanCompaniesNotification:false,
        loadingCleanCompaniesLog:false,
        loadingCompaniesWithoutTemporary:false,
        loadingCleanCompaniesTask:false,
        loadingCleanCompaniesInvoice:false,
        loadingCleanCompaniesPic:false,
        loadingCleanCompaniesRenewal:false,
        loadingCleanCompaniesPayment:false,
        loadingCleanCompaniesReceipt:false,
        loadingCompanyNotFoundExcel:false,
        loadingCompanyIntersectVisionKey:false,
        searchCleanCompaniesLog:null,
        searchCleanCompaniesNotification:null,
        searchCompaniesWithoutTemporary:null,
        searchCompanyIntersectVisionKey:null,
        searchCompanyNotFoundExcel:null,
        searchCleanCompaniesRenewal:null,
        searchCleanCompaniesTask:null,
        searchCleanCompaniesReceipt:null,
        searchCleanCompaniesPayment:null,
        searchCleanCompaniesInvoice:null,
        searchCleanCompaniesPic:null,
        companiesNotFoundHeader:[
            {
                text:'#',
                value:'index',
                align:'start',
            },
            {
                text:'Id',
                value:'company_id',
            },
            {
                text:'Company Name',
                value:'company_name',
            },
            {
                text:'vision key',
                value:'vision_key',
            },
            {
                text:'Verification',
                value:'verification',
            }
        ],
        companiesNotificationHeader:[
            {
                text:'#',
                value:'index',
                align:'start',
            },
            {
                text:'title',
                value:'title',
            },
            {
                text:'type',
                value:'type',
            },
            {
                text:'company id',
                value:'company_id',
            },
            {
                text:'recipient',
                value:'notifiable_id',
            },
            {
                text:'Sender',
                value:'sender_id'
            },
            {
                text:'data',
                value:'data',
            }
        ],
        companiesLogHeader:[
            {
                text:'#',
                value:'index',
                align:'start',
            },
            {
                text:'log class',
                value:'log_class',

            },
            {
                text:'title',
                value:'title',
            },
            {
                text:'description',
                value:'description',
            },
            {
                text:'company_id',
                value:'company_id'
            },
            {
                text:'created_at',
                value:'created_at',
            }
        ],
        companiesPaymentHeader:[
            {
                text:'#',
                value:'index',
                align:'start',
            },
            {
                text:'payment id',
                value:'payment_id',
            },
            {
                text:'company id',
                value:'company_id',
            },
            {
                text:'status',
                value:'status',
            },
            {
                text:'payment method',
                value:'payment_method',
            },
        ],
        
        companiesTaskHeader:[
            {
                text:'#',
                value:'index',
                align:'start',
            },
            {
                text:'name',
                value:'task_name',
            },
            {
                text:'category',
                value:'task_category',
            },
            {
                text:'company',
                value:'company_id',
            },
            {
                text:'user',
                value:'user_id',
            }
        ],
        companiesPicHeader:[
            {
                text:'#',
                value:'index',
                align:'start',
            },
            {
                text:'company id',
                value:'company_id',
            },
            {
                text:'Staff id',
                value:'pic_id',
            },
            {
                text:'Staff role',
                value:'pic_role',
            },
            {
                text:'created_at',
                value:'created_at',
            },
        ],
        companiesReceiptHeader:[
            {
                text:'#',
                value:'index',
                align:'start',
            },
            {
                text:'company id',
                value:'company_id',
            },
            {
                text:'name',
                value:'attachment_name',
            },
            {
                text:'url',
                value:'attachment_url',
            },
            {
                text:'type',
                value:'attachment_type',
            },
            {
                text:'created_at',
                value:'created_at',
            },
        ],

        companiesRenewalHeader:[
            {
                text:'#',
                value:'index',
                align:'start',
            },
            {
                text:'renewal id',
                value:'renewal_id',
            },
            {
                text:'company id',
                value:'company_id',
            },
            {
                text:'status',
                value:'status',
            },
            {
                text:'staff',
                value:'user_id'
            },
            {
                text:'renewal status',
                value:'renewal_status',
            },
            {
                text:'created_at',
                value:'created_at',
            },
        ],
        companiesHeader:[
            {
                text:'#',
                value:'index',
                align:'start',
            },
            {
                text:'Id',
                value:'company_id',
            },
            {
                text:'company name',
                value:'company_name',
            },
            {
                text:'vision key',
                value:'vision_key',
            },
            {
                text:'excel name',
                value:'excel_name',
            },
            {
                text:'Verification',
                value:'verification',
            }
            // {
            //     text:'created date',
            //     value:'created_at',
            // }
        ],
        companiesProblemHeader:
        [
            {
                text:'#',
                value:'index',
            },
            {
                text:'vision_key',
                value:'vision_key',
            },
        ],
        companiesProblem:null,
        secretKey:null,
        loadingCompanyVerification:false,
        loadingCompanyExcel:false,
        verification:'valid',
        totalCompaniesByVerification:null,
        totalCompaniesFromExcel:null,
        api:{
        isSucessful:false,
        isLoading :false,
        isError:false,
        error:null,
        url:null,
        }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="checkCompanyGroupVerification"){
                this.totalCompaniesByVerification = resp.data;
                this.loadingCompanyVerification=false;
            }
            if(resp.class=="checkCompanyExcel"){
                this.totalCompaniesFromExcel = resp.data;
                this.loadingCompanyExcel=false
            }
            if(resp.class=="matchCompanyExcelUsingVisionKey"){
                this.companiesProblem = Object.values(JSON.parse(resp.data.problemcompany));
                this.companiesIntersectVisionKey = resp.data.companies;
            }
            if(resp.class=="checkCompanyNotMatch"){
                this.companiesNotFoundInExcel = resp.data.unmatchedcompanies.companies;
            }
            if(resp.class=="getAllCompaniesWithoutTemporary"){
                this.companiesWithoutTemporary = resp.data;
            }
            if(resp.class=="checkCompaniesRenewal"){
                this.cleanCompanyRenewal = resp.data;

            }

            if(resp.class=="checkCompaniesPayment"){
                this.cleanCompanyPayment = resp.data;
            }

            if(resp.class=="checkCompaniesReceipt"){
                this.cleanCompanyReceipt = resp.data;
            }

            if(resp.class=="checkCompaniesInvoice"){
                this.cleanCompanyInvoice = resp.data;
            }

            if(resp.class=="checkCompaniesPic"){
                this.cleanCompanyPic = resp.data;
            }

            if(resp.class=="checkCompaniesNotification"){
                this.cleanCompanyNotification = resp.data;

            }

            if(resp.class=="checkCompaniesTask"){
                this.cleanCompanyTask = resp.data;
            }

            if(resp.class=="checkCompaniesLog"){
                this.cleanCompanyLog = resp.data;
            }
            this.loadingCleanCompaniesLog = false;
            this.loadingCleanCompaniesTask = false;
            this.loadingCleanCompaniesNotification = false;
            this.loadingCleanCompaniesPic = false;
            this.loadingCleanCompaniesReceipt = false;
            this.loadingCleanCompaniesPayment = false;
            this.loadingCleanCompaniesRenewal = false;
            this.loadingCompanyIntersectVisionKey = false;
            this.loadingCompaniesWithoutTemporary = false;
            this.loadingCompanyNotFoundExcel=false;
            this.loadingCleanCompaniesInvoice = false;

            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted(){
        // this.fetch();
    },
    methods:{
        fetch(){
            let fetchTotalCompaniesApi = this.fetchTotalCompanies();
            this.$api.fetch(fetchTotalCompaniesApi);
        },
        fetchTotalCompanies(){
            this.loadingCompanyVerification = true;
            let tempApi = this.$_.clone(this.api);
            tempApi.method ="GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/company/verification";
            this.$api.fetch(tempApi);
        },
        fetchAllCompaniesExcludeTemporary(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/company/exclude/temporary";
            this.loadingCompaniesWithoutTemporary = true;
            this.$api.fetch(tempApi);
        },
        fetchCompaniesNotFoundInExcel(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method ="GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/company/unmatched";
            this.loadingCompanyNotFoundExcel=true;
            this.$api.fetch(tempApi);
        },
        fetchCompaniesFromExcel(){
            this.loadingCompanyExcel=true;
            let tempApi = this.$_.clone(this.api);
            tempApi.method ="GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+'/developer/company/excel';
            this.$api.fetch(tempApi);
        },
        updateCompanyUnmatchedToInvalid(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method ="POST";
            let companyId =[];
            let formData = new FormData();
            this.companiesNotFoundInExcel.forEach(company => {
                companyId.push(company.company_id);
            });
            formData.append('id',companyId);
            tempApi.params = formData;
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/company/verification/invalid";
            this.loadingCompanyNotFoundExcel=true;
            this.$api.fetch(tempApi);
        },
        updateCompanyToUnknownAllCompaniesWithoutTemporary(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method ="POST";
            let companyId =[];
            let formData = new FormData();
            this.companiesWithoutTemporary.forEach(company => {
                companyId.push(company.company_id);
            });
            formData.append('id',companyId);
            tempApi.params = formData;
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/company/verification/unknown";
            this.loadingCompaniesWithoutTemporary=true;
            this.$api.fetch(tempApi);
        },
        updateCompanyToValidIntersectSalesList(){
           
            let tempApi = this.$_.clone(this.api);
            tempApi.method ="POST";
            let companyId =[];
            let formData = new FormData();
            this.companiesIntersectVisionKey.forEach(company => {
                companyId.push(company.company_id);
            });
            formData.append('id',companyId);
            tempApi.params = formData;
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/company/verification/valid";
            this.loadingCompanyIntersectVisionKey=true;
            this.$api.fetch(tempApi);

        },
        fetchCompaniesIntersectVisionKey(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method ="GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/company/visionKey";
            this.loadingCompanyIntersectVisionKey = true;
            this.$api.fetch(tempApi);
        },
        fetchCompaniesTestingRenewalStatus(){

            let tempApi = this.$_.clone(this.api);
            tempApi.method="GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/company/renewal/"+this.cleanCompanyId;
            this.loadingCleanCompaniesRenewal = true;
            this.$api.fetch(tempApi);
        },
        fetchCompaniesTestingPaymentStatus(){

        let tempApi = this.$_.clone(this.api);
        tempApi.method="GET";
        tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/company/payment/"+this.cleanCompanyId;
        this.loadingCleanCompaniesPayment = true;
        this.$api.fetch(tempApi);
        },
        fetchCompaniesTestingPic(){
        let tempApi = this.$_.clone(this.api);
        tempApi.method="GET";
        tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/company/pic/"+this.cleanCompanyId;
        this.loadingCleanCompaniesPic = true;
        this.$api.fetch(tempApi);
        },

        fetchCompaniesTestingReceipt(){

        let tempApi = this.$_.clone(this.api);
        tempApi.method="GET";
        tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/company/receipt/"+this.cleanCompanyId;
        this.loadingCleanCompaniesReceipt = true;
        this.$api.fetch(tempApi);
        },

        fetchCompaniesTestingNotification(){

        let tempApi = this.$_.clone(this.api);
        tempApi.method="GET";
        tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/company/notification/"+this.cleanCompanyId;
        this.loadingCleanCompaniesNotification = true;
        this.$api.fetch(tempApi);
        },

        fetchCompaniesTestingTask(){
        let tempApi = this.$_.clone(this.api);
        tempApi.method="GET";
        tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/company/task/"+this.cleanCompanyId;
        this.loadingCleanCompaniesTask = true;
        this.$api.fetch(tempApi);
        
        },

        fetchCompaniesTestingLog(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method="GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/company/log/"+this.cleanCompanyId;
            this.loadingCleanCompaniesLog = true;
            this.$api.fetch(tempApi);
        },

        fetchCompaniesTestingInvoice(){

        let tempApi = this.$_.clone(this.api);
        tempApi.method="GET";
        tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/company/invoice/"+this.cleanCompanyId;
        this.loadingCleanCompaniesInvoice = true;
        this.$api.fetch(tempApi);
        },


        ClearCompaniesRenewal(){
            if(this.secretKey=='developer88!'){

                let tempApi = this.$_.clone(this.api);
                tempApi.method = "DELETE";
                tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/company/renewal/"+this.cleanCompanyId;
                this.loadingCleanCompaniesRenewal = true;
                this.$api.fetch(tempApi);
            }
        },
        ClearCompaniesPayment(){
            if(this.secretKey=='developer88!'){

                let tempApi = this.$_.clone(this.api);
                tempApi.method = "DELETE";
                tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/company/payment/"+this.cleanCompanyId;
                this.loadingCleanCompaniesPayment = true;
                this.$api.fetch(tempApi);
            }
        },
        ClearCompaniesPic(){
        if(this.secretKey=='developer88!'){

        let tempApi = this.$_.clone(this.api);
        tempApi.method = "DELETE";
        tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/company/pic/"+this.cleanCompanyId;
        this.loadingCleanCompaniesPic = true;
        this.$api.fetch(tempApi);
}
        },

        ClearCompaniesTask(){
        if(this.secretKey=='developer88!'){

        let tempApi = this.$_.clone(this.api);
        tempApi.method = "DELETE";
        tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/company/task/"+this.cleanCompanyId;
        this.loadingCleanCompaniesTask = true;
        this.$api.fetch(tempApi);
}
        },

        ClearCompaniesReceipt(){
            if(this.secretKey=='developer88!'){

                let tempApi = this.$_.clone(this.api);
                tempApi.method = "DELETE";
                tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/company/receipt/"+this.cleanCompanyId;
                this.loadingCleanCompaniesReceipt = true;
                this.$api.fetch(tempApi);
            }
        },
        ClearCompaniesInvoice(){
            if(this.secretKey=='developer88!'){

                let tempApi = this.$_.clone(this.api);
                tempApi.method = "DELETE";
                tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/company/invoice/"+this.cleanCompanyId;
                this.loadingCleanCompaniesInvoice = true;
                this.$api.fetch(tempApi);
            }
        },
        ClearCompaniesNotification(){
            if(this.secretKey=='developer88!'){

                let tempApi = this.$_.clone(this.api);
                tempApi.method = "DELETE";
                tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/company/notification/"+this.cleanCompanyId;
                this.loadingCleanCompaniesNotification = true;
                this.$api.fetch(tempApi);
            }
        },
        ClearCompaniesLog(){
            if(this.secretKey=='developer88!'){

                let tempApi = this.$_.clone(this.api);
                tempApi.method = "DELETE";
                tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/company/log/"+this.cleanCompanyId;
                this.loadingCleanCompaniesLog = true;
                this.$api.fetch(tempApi);
            }
        }

        
    }
}
</script>